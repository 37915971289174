const details = [
      {
        name: 'Overview',
        id: 'ov'
        // component: () => import('../components/About.vue'),
      },
      {
        name: 'Risk Factors Score',
        id: 'rs'
        // component: () => import('../components/Service.vue'),
      },
      {
        name: 'Perinatal Mental Health Providers',
        id: 'cp'
        // component: () => import('../components/Method.vue'),
      },
      {
        name: 'Provider Ratio',
        id: 'pr'
        // component: () => import('../components/Delivery.vue'),
      },
      {
        name: 'Provider Shortage Gap',
        id: 'sg'
        // component: () => import('../components/Rhizomatic.vue'),
      }
    ]

export default { details };
